<template>
  <el-dialog
  v-dialogDrag
    title="议价"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="500px"
  >
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="仓储" prop="warehousId">
              <el-select class="width-100" v-model="form.warehousId" :disabled="true">
                <el-option
                  v-for="item in warehousIdOptions"
                  :key="item.gradeId"
                  :label="item.value"
                  :value="item.gradeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="价格" prop="price">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="isAddLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        warehousId: null,
        price: null
      },
      rules: {
        warehousId: [
          { required: true, message: "请选择仓储", trigger: "change" }
        ],
        price: [{ required: true, message: "请输入价格", trigger: "change" }]
      },
      warehousIdOptions: [],
      isAddLoading: false
    };
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.getWarehousIdOptions();
    },
    getWarehousIdOptions() {
      http.postFront(protocolFwd.param_getCommodityGrade).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.warehousIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_sellHangStockOrder.param = this.form;
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_sellHangStockOrder)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        warehousId: null,
        price: null
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.dialogVisible = false;
    }
  }
};
</script>